<template>
  <div class="st-container">
    <LandingBanner :dealTypeCode="dealTypeCode" :areaDest="areaDestination"/>
    <LandingDeal v-if="landingDealData.length>0" :preferDestCode="preferDestCode" :preferDealType="preferDealType" v-model="dealTypeCode" @changeDest="updateAreaDest"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LandingContent',
  computed: {
    ...mapGetters({
      landingDealData: 'GET_LANDING_DEAL_DATA',
    }),
  },
  components: {
    LandingBanner: () => import('@/components/landing/LandingBanner'),
    LandingDeal: () => import('@/components/landing/LandingDeal'),
  },
  props: {
    msg: String,
    preferDestCode: String,
    preferDealType: String,
  },
  data() {
    return {
      dealTypeCode: '',
      areaDestination: '',
    };
  },
  methods: {
    updateAreaDest(dest) {
      this.areaDestination = dest;
    },
  },
};
</script>

<style>
@media (min-width: 1200px){
  .container {
    max-width: 1500px;
  }
}
</style>
